<template>
    <div>
        <b-row class="match-height">
            <b-col lg="3">
                <b-card-code title="Score">
                    <b-list-group>
                        <b-list-group-item class="flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5>
                                    Score: {{ score }}
                                </h5>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item v-for="sectionScore in sectionScores" :key="sectionScore[0]" class="flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5>
                                    {{sectionScore[0]}}: {{ sectionScore[1] }}
                                </h5>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-code>
            </b-col>
            <b-col lg="9">
                <b-card no-header no-body>
                    <b-card-header class="pb-0">
                        <div>
                            <b-card-title>
                                {{ questionnaire_title }}
                            </b-card-title>
                            <b-card-sub-title>
                                {{ questionnaire_subcategory }}
                            </b-card-sub-title>
                        </div>
                        <b-card-sub-title>
                            <div>
                            {{ user }}
                            </div>
                            <div>
                                <b-badge pill variant="light-secondary" class="cursor-pointer">
                                    <span>&#x1F44D; </span>
                                    <span>{{ firstReaction }}</span>
                                </b-badge>
                                <b-badge pill variant="light-secondary" class="cursor-pointer ml-1">
                                    <span>&#x1F917;  </span>
                                    <span>{{ secondReaction }}</span>
                                </b-badge>
                            </div>
                        </b-card-sub-title>
                    </b-card-header>
                    <div class="m-2">
                        <!-- Table Top -->
                        <b-row>
                            <!-- Per Page -->
                            <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            >
                            <!-- page length -->
                            <b-form-group
                                label="Show"
                                label-cols="5"
                                label-align="left"
                                label-size="sm"
                                label-for="sortBySelect"
                                class="text-nowrap mb-md-0 mr-1"
                            >
                                <b-form-select
                                id="perPageSelect"
                                v-model="perPage"
                                size="sm"
                                inline
                                :options="pageOptions"
                                />
                            </b-form-group>
                            <b-button v-if="loggedUser.permissions != undefined && (loggedUser.permissions.includes('Manage Everything') || loggedUser.permissions.includes('Can Give Support'))" variant="primary" v-b-modal.modal-login>Add message</b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="message_reactions"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                    >
                        <!-- Column: Reaction -->
                        <template #cell(message)="data">
                            <span class="font-weight-bold d-block text-nowrap">
                                {{ data.item.reaction_text }}
                            </span>
                        </template>

                        <!-- Column: Response -->
                        <template #cell(response)="data">
                            <span class="font-weight-bold d-block text-nowrap">
                                {{ data.item.reaction_response_text }}
                            </span>
                        </template>
                        
                        <!-- Column: Commenter -->
                        <template #cell(commenter)="data">
                            <router-link :to="{name: 'user-questionnaires', params: {id: data.item.userfollow.following_u.id} }">
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ data.item.userfollow.following_u.username }}
                                </span>
                            </router-link>
                        </template>

                        <!-- Column: Actions  -->
                        <template #cell(actions)="data">
                            <b-badge variant="light-danger" @click="banUser(data.item.userfollow.following_u.id, data.item.userfollow.following_u.username, data.item.userfollow.following_u.name)" v-b-tooltip.hover.top="'Ban user'" v-if="loggedUser.username != data.item.userfollow.following_u.username && !checkIfHasAdminRole(data.item.userfollow.following_u.roles) && (loggedUser.permissions.includes('Manage Everything') || loggedUser.permissions.includes('Manage Users'))">
                                <feather-icon icon="SlashIcon" />
                            </b-badge>
                            <b-badge variant="light-danger ml-1" @click="removeResponse(data.item.id)" v-b-tooltip.hover.top="'Remove response'">
                                <feather-icon icon="XCircleIcon" />
                            </b-badge>
                            <b-badge variant="light-danger ml-1" @click="removeMessage(data.item.id)" v-b-tooltip.hover.top="'Remove message'" v-if="!checkIfHasAdminRole(data.item.userfollow.following_u.roles)">
                                <feather-icon icon="Trash2Icon" />
                            </b-badge>
                        </template>
                    </b-table>
                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                        <!-- pagination -->
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                            >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                            </b-pagination>
                        </div>
                    </b-card-body>

                    <!-- modal login-->
                    <b-modal
                    id="modal-login"
                    cancel-variant="outline-secondary"
                    ok-title="Send"
                    cancel-title="Close"
                    centered
                    title="Write message"
                    @ok="sendSupportMessage"
                    >
                        <b-form>
                            <b-form-group>
                                <label for="textarea-default">Message</label>
                                <b-form-textarea
                                v-model="message"
                                id="textarea-default"
                                placeholder="Support message"
                                rows="4"
                                />
                            </b-form-group>
                        </b-form>
                    </b-modal>

                </b-card>
            </b-col>
            
            <b-col lg="12" v-if="score <= 3">
                <b-card no-header no-body>
                    <b-card-header class="pb-0">
                       <div>
                           <b-card-title>
                               User Low score response
                           </b-card-title>
                       </div>
                    </b-card-header>
                    <b-card-body class="mt-1">
                        <b>Has made contact:</b>
                        <span v-if="responseData.answer == 1">
                            Yes
                        </span>
                        <span v-else-if="responseData.answer == 0">
                            No
                        </span>
                        <span v-else>
                            No response yet
                        </span><br>
                        <b>Additional info:</b> <span>{{ responseData.answerText }}</span>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
       
        <!-- Questions and answers -->
        <b-card-code :title="`${questionnaire_title} details`">
            <b-list-group v-for="question in questions" :key="question.id">
                <b-list-group-item v-if="question.questionnaireresults.length > 0" class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5>
                    {{ question.question_text }}
                    </h5>
                </div>
                <b-card-text>
                    <small
                    v-for="(questionnaireresults,
                    index) in question.questionnaireresults"
                    :key="index"
                    >
                    <div>
                        <span class="color-mhc-light font-weight-bold">Answer: </span> 
                        <span v-if="question.is_scale == 1" class="color-mhc-dark font-weight-bold">{{ questionnaireresults.answer.answer_text | changeScaleText }}</span>
                        <span v-else class="color-mhc-dark font-weight-bold">{{ questionnaireresults.answer.answer_text }}</span>
                    </div>
                    </small>
                </b-card-text>
                </b-list-group-item>
            </b-list-group>
        </b-card-code>
    </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code";
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    VBTooltip,
    BModal, 
    VBModal,
    BForm,
    BFormTextarea,
    BListGroup, 
    BListGroupItem,
} from "bootstrap-vue";

export default {
    components: {
        BCardCode,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardSubTitle,
        BCardText,
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BModal, 
        VBModal, 
        BForm,
        BFormTextarea,
        BListGroup, 
        BListGroupItem,
    },
    data() {
        return {
            data: [], // reactions
            questionnaire_title: "",
            questionnaire_subcategory: "",
            questionnaireCateory: "",
            message: "",
            user: "",
            score: 10,
            sectionScores: [],
            message_reactions: [],
            questions: [],
            firstReaction: 0,
            secondReaction: 0,
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "message", label: "Message" },
                { key: "response", label: "Response" },
                { key: "commenter", label: "Commenter" },
                { key: "actions", label: "Actions", class: "text-center" },  
            ],
            loggedUser: {},
            responseData: {
                "answer" : null,
                "answerText": null
            },
        };
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
    },
    filters: {
        changeScaleText: function (value) {
            if (!value) return ''
            value = value.replace('Scale ', '')
            value = value + ' out of 10'
            return value
        }
    },
    methods: {
        checkIfHasAdminRole(roles) {
            for(let i = 0; i < roles.length; i++) {
                if(roles[i].name == "Super Admin" || roles[i].name == "Admin") {
                    return true;
                }
            }
            return false;
        },
        banUser(user_id, username, fullName) {
            this.$bvModal
                .msgBoxConfirm('User ' + fullName + ' (' + username + ') will be banned from the app!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/ban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully banned from the app!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error banning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        getAllMessages() {
            //Return received message list
            let submission_id = this.$route.params.submission_id

            this.$http.get("/api/auth/admin/questionnaire/reactions/" + submission_id)
            .then((res) => {
                if(res != undefined) {
                    this.data = res.data.reactions;
                    this.message_reactions = [];
                    this.secondReaction = 0;
                    this.firstReaction = 0;
                    // Count reactions
                    if(this.data.length > 0) {
                        // Do count for all reactions
                        for(let i=0; i<this.data.length; i++) {
                            if(this.data[i].reaction_type == 'message') {
                                this.message_reactions.push(this.data[i])
                            } else {
                                if(this.data[i].reaction_text == '&#x1F44D;') {
                                    // Like
                                    this.firstReaction++
                                } else if (this.data[i].reaction_text == '&#x1F917;') {
                                    // Hug
                                    this.secondReaction++
                                }
                            }
                        }
                    }
                    
                    // Add questionnaire data
                    this.questionnaire_title = res.data.userquestionnairetook.questionnaire.title
                    this.questionnaire_subcategory = res.data.userquestionnairetook.questionnaire.subcategory.questionnairecategory.name

                    // Add User data
                    this.user = res.data.userquestionnairetook.user.username

                    // Add comments length
                    this.totalRows = this.data.length;
                }
            })
            .catch((error) => {
                console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        removeMessage(id) {
            this.$bvModal
            .msgBoxConfirm('This message will be removed!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/user/reactions/" + id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction was successfully removed!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });  
                        
                        // Get fresh messages data
                        this.getAllMessages();                 
                    }).catch((error) => {
                        console.log(error)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error removing message!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }, 
        removeResponse(id) {
            this.$bvModal
            .msgBoxConfirm('The response to this message will be removed!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/user/reactions/message/response/" + id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction was successfully removed!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        // Get fresh messages data
                        this.getAllMessages();                 
                    }).catch((error) => {
                        console.log(error)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error removing message!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        sendSupportMessage() {
            let id = this.$route.params.submission_id
            
            let reaction = {
                "reaction_text": this.message,
                "user_questionnaire_id": id
            }

            this.$http.post("/api/auth/user/reactions/message", reaction)
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Message successfully sent",
                        icon: "CheckSquareIcon",
                        variant: "success",
                    },
                });
                
                // Get fresh messages
                this.getAllMessages()
                
                // Reset form
                this.message = ""
            })
            .catch((error) => {
                console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        getScore() {
            //Return received message list
            let submission_id = this.$route.params.submission_id

            this.$http.get("/api/auth/questionnaire/score/" + submission_id)
            .then((res) => {
                if(res != undefined) {
                    this.questionnaireCateory = res.data.questionnaire_category;
                    if(this.questionnaireCateory == 'My mental health and my life situation(s)') {
                        this.score = res.data['Questionnaire Score'].section_score;
                        this.sectionScores = [
                            ["Biological", res.data.sections['Section B - Biological question'].section_score],
                            ["Psychological", res.data.sections['Section B - Psychological question'].section_score],
                            ["Social", res.data.sections['Section B - Social question'].section_score]
                        ]
                    } else if(this.questionnaireCateory == 'My mental health and my job/career') {
                        this.score = res.data['Questionnaire Score'].section_score;
                        this.sectionScores = [
                            ["Before Work", res.data.sections['Before Work'].section_score],
                            ["At Work", res.data.sections['At Work'].section_score],
                            ["After Work",  res.data.sections['After Work'].section_score]
                        ]
                    } else if(this.questionnaireCateory == 'My mental wellbeing') {
                        this.score = res.data['Questionnaire Score'].section_score;
                        this.sectionScores = [
                            ["Calm & Inner peace", res.data.sections['Calm & Inner peace'].section_score],
                            ["Joy and Contentment", res.data.sections['Joy and Contentment'].section_score],
                            ["Adaptability", res.data.sections['Adaptability'].section_score],
                            ["Rest & Relaxation", res.data.sections['Rest & Relaxation'].section_score],
                            ["Health & Lifestyle", res.data.sections['Health & Lifestyle'].section_score],
                            ["Relationships & Connections", res.data.sections['Relationships & Connections'].section_score],
                            ["Satisfaction & Fulfillment", res.data.sections['Satisfaction & Fulfillment'].section_score],
                        ]
                    } else {
                        this.score = res.data['Questionnaire Score'].section_score;
                    }                    
                }
            })
            .catch((error) => {
                console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        getQuestionnaireResults() {
            //Return received message list
            let submission_id = this.$route.params.submission_id

            this.$http.get("/api/auth/questionnaire/result/" + submission_id)
            .then((res) => {
                if(res != undefined) {
                    this.responseData = {
                        "answer": res.data.user_questionnaire.has_made_contact,
                        "answerText": res.data.user_questionnaire.response
                    }
                    this.questions = res.data.questionnaire.questions;
                }
            })
            .catch((error) => {
                console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        }
    },
    mounted() {
        this.loggedUser = JSON.parse(localStorage.getItem('userData'))
        this.getAllMessages()
        this.getScore()
        this.getQuestionnaireResults()
    }
}
</script>

<style>
.card-subtitle {
    margin-top: 0rem;
}
</style>

<style scoped>
.list-group-item {
  border: 0px;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125);
}
</style>